import { Fragment } from 'react';
import { useCashiersContext, useMetricListContext } from '../../hooks';
import { centsToCurrency, isNotEmpty, isValidDate } from '../../utils';
import { Button } from 'react-md';
import moment from 'moment';
import './CashierDetailsModal.scss';

const CashierDetailsModal = () => {
  const { selectedCashier, setCurrentDialog } = useCashiersContext();
  const { cashiers = [] } = useMetricListContext();
  const cashier = cashiers.find(c => c.id === selectedCashier.id);

  const {
    iPhoneBattery,
    paxBattery,
    sledBattery,
    latestTransaction,
    lotNames,
    cashRevenue,
    revenue,
    creditRevenue,
    paymentRevenue,
    prepaidRevenue,
    transactionCount,
    cashTransactionCount,
    creditTransactionCount,
    exemptTransactionCount,
    paymentTransactionCount,
    permitTransactionCount,
    prepaidTransactionCount,
    vipTransactionCount
  } = cashier;

  const timestamp = isValidDate(latestTransaction?.transactionTime) ? moment(latestTransaction?.transactionTime).format('M/D/YY h:mm a') : '-';

  function handleClick() {
    setCurrentDialog('select event');
  }

  const displayBattery = (battery) => battery === null ? '' : `${battery}%`;

  const battery = iPhoneBattery === null ? {
    id: 'pax-battery',
    label: 'PAX Battery',
    display: displayBattery(paxBattery)
  } : {
    id: 'iphone-battery',
    label: 'iPhone Battery',
    display: displayBattery(iPhoneBattery)
  };

  const cashierDetails = [
    {
      id: 'lots',
      label: 'Lots',
      display: lotNames.join(', ') || '—'
    },
    battery,
    {
      id: 'reader-battery',
      label: 'Reader Battery',
      display: displayBattery(sledBattery)
    },
    {
      id: 'latest-transactions',
      label: 'Most recent transaction',
      display: timestamp
    }
  ];

  const revenueDetails = [
    {
      id: 'total-revenue',
      label: 'Total Revenue',
      display: centsToCurrency(revenue) || '—'
    },
    {
      id: 'cash-revenue',
      label: 'Cash Revenue',
      display: centsToCurrency(cashRevenue) || '—'
    },
    {
      id: 'credit-revenue',
      label: 'Credit Revenue',
      display: centsToCurrency(creditRevenue) || '—'
    },
    {
      id: 'prepaid-revenue',
      label: 'Prepaid Revenue',
      display: centsToCurrency(prepaidRevenue) || '—'
    },
    {
      id: 'payment-revenue',
      label: 'Payment Revenue',
      display: centsToCurrency(paymentRevenue) || '—'
    }
  ];

  const transactionDetails = [
    {
      id: 'total-transactions',
      label: 'Total Transactions',
      display: transactionCount || 0
    },
    {
      id: 'total-cash',
      label: 'Total Cash',
      display: cashTransactionCount || 0
    },
    {
      id: 'total-credit',
      label: 'Total Credit',
      display: creditTransactionCount || 0
    },
    {
      id: 'total-prepaids',
      label: 'Total Prepaids',
      display: prepaidTransactionCount || 0
    },
    {
      id: 'total-exempt',
      label: 'Total Exempt',
      display: exemptTransactionCount || 0
    },
    {
      id: 'total-permits',
      label: 'Total Permits',
      display: permitTransactionCount || 0
    },
    {
      id: 'total-vips',
      label: 'Total VIPs',
      display: vipTransactionCount || 0
    },
    {
      id: 'total-payments',
      label: 'Total Payments',
      display: paymentTransactionCount || 0
    }
  ];

  return (
    <>
      {isNotEmpty(selectedCashier) &&
        <div className="cashier-details-container">
          <dl className="transaction__list">
            {cashierDetails.map(({ id = '', label = '', display = '' }, idx) => (
              <Fragment key={id || idx}>
                <dt className="transaction__term">
                  {label}
                </dt>
                <dd className="transaction__detail cashier__detail">
                  {display}
                </dd>
              </Fragment>
            ))}
          </dl>
          <h1 className="dl-header">
            Revenue
          </h1>
          <dl className="transaction__list">
            {revenueDetails.map(({ id = '', label = '', display = '' }, idx) => (
              <Fragment key={id || idx}>
                <dt className="transaction__term">
                  {label}
                </dt>
                <dd className="transaction__detail cashier__detail">
                  {display}
                </dd>
              </Fragment>
            ))}
          </dl>
          <h1 className="dl-header">
            Successful Transactions
          </h1>
          <dl className="transaction__list">
            {transactionDetails.map(({ id = '', label = '', display = '' }, idx) => (
              <Fragment key={id || idx}>
                <dt className="transaction__term">
                  {label}
                </dt>
                <dd className="transaction__detail cashier__detail">
                  {display}
                </dd>
              </Fragment>
            ))}
          </dl>
          <div className="cashier-details-footer">
            <Button
              theme="primary"
              className="manage-cash-button"
              themeType="contained"
              onClick={handleClick}
            >
              Manage Cash
            </Button>
          </div>
        </div>
      }
    </>
  );
};

export default CashierDetailsModal;
