import { SortIcon } from '../../assets/react-icons';
import { useTableSort } from '../../hooks';
import { truncate } from '../../utils';
import cx from 'classnames';
import './Table.scss';

// Body object keys must correspond to a head item ID
const Table = ({ head = [], body = [], className, children }) => {
  const { sortColumn, sortDirection, tableData, handleSort } = useTableSort(body);

  function dashCase(val) {
    return val.replace(/\s+/g, '-').toLowerCase();
  }

  return (
    <div className="table__container">
      <table className={cx(className, 'table__table')}>
        <thead className="table__head">
          <tr className="table__row--head">
            {head.map(item => {
              const { label = '', id = '', sortable } = item;
              const currentItem = sortColumn === id;
              const direction = currentItem ? sortDirection : '';

              return (
                <th className={cx('table__th')} key={id}>
                  {sortable ? (
                    <button
                      aria-label={`Sort table by ${label.toLowerCase()} in ${direction === 'asc' ? 'descending' : 'ascending'} order`}
                      className="table__button--sort"
                      onClick={() => handleSort(id)}
                      type="button"
                    >
                      {label} <SortIcon direction={direction} />
                    </button>
                  ) : (
                    label
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="table__body">
          {tableData.map((row, index) => {
            const rowKey = `row-${index + 1}`;
            const { status = 'normal' } = row;

            return (
              <tr
                className={cx('table__row--body', { 'table__row--body-clickable': row.onRowClick })}
                data-status-indicator={status.sortValue}
                onClick={row?.onRowClick}
                key={rowKey}
              >
                {head.map(category => {
                  const cellKey = `${rowKey}-${category.id}`;
                  const data = row[category.id];
                  const { clickable = false, displayValue, truncateValue } = data;
                  const dataType = typeof displayValue;
                  const title = ['number', 'string'].includes(dataType) ? displayValue : '';
                  const highlightCondition = ['sledBattery', 'iPhoneBattery', 'paxBattery'].includes(category.id) && data.sortValue < 31 && data.sortValue > 0;

                  return (
                    <td
                      className={cx(`table__td${clickable ? '--clickable' : ''}`, dashCase(category.label))}
                      key={cellKey}
                      title={title}
                    >
                      <span className={cx(highlightCondition && 'highlight-cell')}>
                        {truncateValue ? truncate(displayValue, truncateValue) : displayValue}
                      </span>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {children}
      </table>
    </div>
  );
};

export default Table;
