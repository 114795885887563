import {
  FontIcon,
  DropdownMenu,
  MenuConfigurationProvider,
  useMenuVisibility,
  AppBar,
  AppBarTitle,
  AppBarAction,
  CloseSVGIcon,
  MenuItemGroup,
  MenuItemRadio
} from 'react-md';
import { useTableSort } from '../../hooks';
import { SortIcon } from '../../assets/react-icons';
import cx from 'classnames';
import './MobileList.scss';

function Header() {
  const { setVisible } = useMenuVisibility();

  return (
    <AppBar theme="clear">
      <AppBarTitle className="mobile-menu-title">Select an attribute to show in list view:</AppBarTitle>
      <AppBarAction first last onClick={() => setVisible(false)}>
        <CloseSVGIcon />
      </AppBarAction>
    </AppBar>
  );
}

const MobileList = ({
  className,
  data,
  hasNextStep,
  columns,
  menuItems,
  onRowClick,
  setColumn
}) => {
  const { body, head } = data;
  const { mainColumn, secondaryColumn } = columns;
  const { handleSort, sortColumn, sortDirection, tableData } = useTableSort(body);

  function changeColumn(column) {
    if (column === secondaryColumn) {
      return;
    }

    if (sortDirection === 'desc') {
      handleSort(mainColumn);
    }

    setColumn(column);
  }

  function highlightCell(value) {
    return ['sledBattery', 'iPhoneBattery', 'paxBattery'].includes(secondaryColumn) && value > 0 && value <= 30 && 'highlight-cell';
  }

  const primaryHeader = head.find((item) => item.id === mainColumn);
  const secondaryHeader = head.find((item) => item.id === secondaryColumn);

  return (
    <div className={cx('mobile-list-container', className)}>
      <div className="mobile-list-header">
        <ul>
          <li>
            {primaryHeader.sortable && (
              <button
                aria-label="sort-main-column"
                className="table__button--sort"
                onClick={() => handleSort(mainColumn)}
                type="button"
              >
                <SortIcon direction={sortColumn === primaryHeader.id ? sortDirection : ''} />
                <span className="header-text">{primaryHeader.label}</span>
              </button>
            )}
          </li>
          <li>
            {secondaryHeader.sortable && (
              <button
                aria-label="sort-secondary-column"
                className="table__button--sort sort-secondary-column truncate"
                onClick={() => handleSort(secondaryColumn, 'secondary')}
                type="button"
              >
                <SortIcon direction={sortColumn === secondaryHeader.id ? sortDirection : ''} />
                <span className="header-text">{secondaryHeader.label}</span>
              </button>
            )}
          </li>
        </ul>
        {menuItems && (
          <>
            <MenuConfigurationProvider
              sheetHeader={<Header />}
              sheetFooter={<div className="mobile-sheet-footer" />}
              renderAsSheet
              label="renderAsSheet"
              sheetPosition="top"
              sheetVerticalSize="recommended"
            >
              <DropdownMenu
                id="mobile-action-sheet"
                icon={<FontIcon>more_vert</FontIcon>}
              >
                <MenuItemGroup>
                  {menuItems.map((item) => (
                    <MenuItemRadio
                      key={item.value}
                      id={`${item.value}-option`}
                      checked={item.value === secondaryColumn}
                      onCheckedChange={() => changeColumn(item.value)}
                      iconAfter
                    >
                      {item.label}
                    </MenuItemRadio>
                  ))}
                </MenuItemGroup>
              </DropdownMenu>
            </MenuConfigurationProvider>
          </>
        )}
      </div>
      <div className="mobile-list-body">
        {tableData.map((item, index) => {
          const { status = 'normal', rowData } = item;
          const main = item[mainColumn].displayValue;
          const secondary = item[secondaryColumn];

          return (
            <ul
              data-status-indicator={status.sortValue}
              onClick={() => onRowClick && onRowClick(rowData)}
              className="list-item"
              key={`${mainColumn}-${index}`}
            >
              {item[mainColumn].icon}
              <li className="column-main">{main}</li>
              <li className={cx('column-secondary', highlightCell(secondary.sortValue))}>{secondary.displayValue}</li>
              {hasNextStep && <li><FontIcon>navigate_next</FontIcon></li>}
            </ul>
          );
        })}
      </div>
    </div>
  );
};

export default MobileList;
